.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  //   text-overflow: ellipsis;
  overflow-x: scroll;
}
.has-overflow {
  position: relative;
  border: 2px solid red;
  padding: 4px;
  margin: 2px;
}
.has-overflow2 {
  margin: 2px;
  position: relative;
  border: 2px solid rgb(59, 254, 0);
  padding: 4px;
}

@media (max-width: 767px) {
  .calendario-wrapper {
    max-height: 80vw;
  }
  .calendar-container {
    //Para dias de 3 en 3
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .ant-fullcalendar-fullscreen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ant-fullcalendar-fullscreen .ant-fullcalendar-date-panel {
    width: 100%;
  }

  .ant-fullcalendar-fullscreen .ant-fullcalendar-date-panel table {
    width: 30%;
  }

  .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel {
    display: none;
  }

  .calendario {
    display: flex;
    flex-wrap: wrap;
    font-size: 60px;
    width: 4vw;

    .ant-picker-cell-inner {
      width: 100%;
    }

    .ant-picker-cell:not(.ant-picker-cell-selected):hover {
      background-color: transparent;
    }

    .ant-picker-cell-today .ant-picker-cell-inner {
      border-color: #1890ff;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-selected.ant-picker-cell-range-start
      .ant-picker-cell-inner,
    .ant-picker-cell-selected.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-selected.ant-picker-cell-range-start.ant-picker-cell-range-end
      .ant-picker-cell-inner {
      background-color: #1890ff;
      border-color: #1890ff;
    }

    .ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner {
      color: rgba(0, 0, 0, 0.25);
    }

    .ant-picker-cell-inner {
      margin-bottom: 2px;
    }
  }

  .ant-badge-status-text {
    font-size: 10px;
    max-width: 60%;
  }
}
