.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* Asegúrate de que el dashboard ocupe el ancho completo */
}

.news-post-it p {
  white-space: pre-wrap;
  font-size: 20px;
}

.dashboard-title{
  color:black;
  display:flex;
  flex-direction: center;
}
.news-section {
  display: flex;
  flex-direction: column;
  /* Cambia esto para que las noticias se apilen verticalmente */
  width: 100%;
  /* Establece el ancho a 100% para que ocupe todo el espacio disponible */
}

.news-post-it {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin: 10px 0;
  /* Añade margen arriba y abajo para separar las noticias */
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Ocupa el ancho completo */
}

.news-header {
  display: flex;
  justify-content: space-between;
  /* Separa la fecha y el título */
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  /* Añade un borde debajo del encabezado */
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.news-header h3 {
  margin: 0;
  font-weight: 500;
  /* Peso de la fuente para el título /
font-size: 20px; / Tamaño de la fuente para el título /
color: #333; / Color del texto para el título /
flex: 1; / Permite que el título ocupe todo el espacio disponible */
}

.news-header .date {
  margin-left: 12px;
  color: #999;
  /* Color del texto para la fecha /
font-size: 16px; / Tamaño de la fuente para la fecha */
}

.news-content p {
  margin: 0;
  color: #666;
  /* Color del texto para el contenido /
line-height: 1.6; / Altura de línea para el contenido */
}

.add-news-button {
  position: fixed;
  /* Fija el botón en la pantalla /
right: 24px; / Distancia desde el borde derecho de la pantalla /
top: 24px; / Distancia desde el borde superior de la pantalla */
}

.news-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}


.news-buttons button {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

/* Estilos para botones al pasar el cursor */
.news-buttons button:hover {
  opacity: 0.8;
}