.users {
}

.mapa{
    align-items: center;
    justify-content: center;
    display: flex;
    width: auto
}

.mapa-view{
    width: 100vh;
    overflow: hidden;
}
#managerTable_1 {
   
    overflow: auto;
    max-height: calc(100vh - 0px); /* 200px es la altura de tu barra horizontal */
   
}



.table-stock {
    border-collapse: collapse;
    border: 1px solid black;
    margin: auto; /* Centra la tabla */
    text-align: left;
    position: relative;
   
}
.th-stock {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
.thead-stock {
    background-color: #87CEEB; /* Azul claro cielo */
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    border: 1px solid black;
 
}

.td-stock, .th-stock {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    border: 1px solid black;
    
}

.stock-low {
    background-color: rgb(252, 158, 158);
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    border: 1px solid black;
}

.titulo-tabla-stock{
    color: black;
    text-align: center;
    
}

.stock-bajo {
    background-color: red; 
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    border: 1px solid black;// Para stock muy bajo (menos de un tercio del óptimo)
  }
  
  .stock-medio {
    background-color: grey;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 15px;
    border: 1px solid black; // Para stock bajo pero más de un tercio del óptimo
  }