@import "../../../scss/index.scss";

.login-form {
  .ant-form-item:last-of-type {
    margin: 0;
  }

  .login-form__form {
    display: flex;
    align-items: center;
    justify-content: center;
    //justify-items: space-between;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .login-form__input {
    .ant-input-prefix {
      i {
        form-size: 22px;
      }
    }

    input {
      font-size: 20px;
      padding: 20px 20px 20px 40px !important;
      &:focus {
        border-color: $primary-color;
        box-shadow: none;
      }
    }
  }

  &__button {
    width: 100%;
    font-size: 22px;
    background-color: $primary-color;
    color: $font-light;
    height: 42px;
    margin-top: 0px;

    &:hover {
      background-color: $primary-color-hover;
      color: $font-light;
    }
    &:focus,
    &:active {
      background-color: $primary-color;
      color: $font-light;
      border: 0;
    }
  }
}
