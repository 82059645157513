/* Añadir esto a tu archivo CSS o en el estilo global */
.separator-row {
  background-color: black !important;
  height: 10px; /* Ajusta la altura según sea necesario */
}
.separator-row td {
  padding: 0;
}

.even-row {
  background-color: #f2f2f2; /* Gris claro */
}

.odd-row {
  background-color: #ffffff; /* Blanco */
}
