@import "../scss/index.scss";
.leaflet-container {
  width: 100%;
  height: 100%;
}
.layout-admin {
  transition: margin-left 0.2s;
  margin-left: 170px;
  @media (min-width: 800px) {
    margin-left: 170px;
  }

  z-index: 30;
  &__header {
    z-index: 30; //El z-index indica que elemento está por encima de que elemento (para gestionar  la superposición de elemntos)
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
  }

  &__content {
    min-height: calc(100vh - 65px);
    padding: 90px 25px 25px 25px;
  }

  &__footer {
    padding: px;
  }
}

.layout-admins {
  transition: margin-left 0.2s;
  margin-left: 0px;
  @media (min-width: 800px) {
    margin-left: 170px;
  }

  z-index: 1;
  &__header {
    z-index: 1; //El z-index indica que elemento está por encima de que elemento (para gestionar  la superposición de elemntos)
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
  }

  &__content {
    min-height: calc(100vh - 65px);
    padding: 90px 25px 25px 25px;
  }

  &__footer {
    padding: px;
  }
}
