@import "../../../scss/index.scss";

.menu-top {
  display: flex;

  button {
    color: $font-light;

    &:hover {
      color: $primary-color;
    }
    &:focus,
    &:active {
      color: $font-light;
    }
  }

  &__left {
    &-logo {
      height: 50px;
      width: 50px;
      margin: 0px 20px 0px 20px;
    }
  }

  &__menu {
    display: inline-block;
    @media (min-width: 800px) {
      display: none;
    }
  }

  &__right {
    position: absolute;
    right: 0;
  }
  &__username {
    color: white;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    @media (max-width: 800px) {
      display: none;
    }
  }
}
