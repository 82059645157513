.fila-domingo {
    background-color: yellow;
}

.total-pago {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .total-pago {
      font-size: 14px;
    }
  
    .ant-table {
      font-size: 12px;
    }
  
    .ant-picker {
      width: 100%;
    }
  
    .ant-btn {
      width: 100%;
      margin-top: 10px;
    }
  }