/* Estilos en un archivo CSS, por ejemplo, styles.css */
.tarjeta-piso {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .color-entrada-salida {
    background-color: rgb(245, 66, 66);
  }

  .color-limpio{
    background-color: greenyellow;
  }
  
  .color-salida {
    background-color: rgb(238, 238, 82);
  }
  
  .color-listado {
    background-color: blue;
  }

  .color-asignado {
    background-color: rgb(106, 93, 224);
  }

  .vista-limpiador {
    background-color: rgb(238, 238, 82);
  }
  
  .hide {
    visibility: hidden;
  }

  .boton-estilo {
    border-radius: 5px; /* Bordes redondeados */
    margin: 5px; /* Margen alrededor del botón */
    padding: 10px 15px; /* Espacio interno del botón */
    border: 1px solid #ddd; /* Bordes del botón */
    background-color: #f0f0f0; /* Color de fondo */
    cursor: pointer; /* Cursor en forma de mano al pasar el ratón por encima */
    transition: background-color 0.3s; /* Transición suave al cambiar de color */
  }
  
  .boton-estilo:hover {
    background-color: #e0e0e0; /* Cambiar el color al pasar el ratón por encima */
  }
  