.tarjeta{
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    display:flex;
    flex-direction: row;
    display: inline-block;
    flex-wrap: wrap;
    justify-content: flex-start; /* Alinea las tarjetas al inicio del contenedor */
    gap: 10px; /* Espacio entre tarjetas */
    position: relative;
}
.progress{
    margin: 10px;
}