// DNIUpload.scss
.vic {
    overflow-x: hidden;
    /* Ocultar la barra de desplazamiento horizontal en dispositivos móviles */
    padding-left: 5%;
    /* Espacio a la izquierda */
    padding-right: 5%;
    /* Espacio a la derecha */
}

.div-background-check-in {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
    background-image: url("../../../assets/img/jpg/fondo-ciudad.jpg");
    background-position: center;
    background-size: cover;
}

.div-content-check-in {
    background-color: rgb(236, 243, 241);
    opacity: 1;
    padding: 30px;
    overflow: hidden;
    border-radius: 5%;  
    height: auto;
    width: auto;  
}

.formularioClientes{
    padding:10px;
}
.check-in-title h1{
    color: rgb(7, 7, 7);
    
}

.formulario-steps-index{
    width: 100%;
}

.step-name{
    display: flex;
    flex:1;
    padding: 20px;
    width: auto;
}

.inquilinos{
    display: block;
    margin: 5px;
    width: 70%;
    border-radius: 10%;
    size: 20;
}

.content-inquilinos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Espacio entre botones */
  }

  .proteccion-datos{
    padding: 1px;
    margin: 1px;
  }

  .button-prev {
    margin-top: 20px; /* Espacio adicional por encima del botón 'Anterior' */
    align-self: flex-end; /* Alinea el botón 'Anterior' a la derecha */
  }

.steps-action-form .button-prev {
    position: absolute; /* Posicionamiento absoluto */
    left: 20px; /* Espaciado desde la izquierda */
    bottom: 20px; /* Espaciado desde abajo */
  }

.div-content-check-in {
    .steps-content-form,.steps-action-form{
        padding: 15px;
    }

    // Responsive para tablets
    @media (max-width: 768px) {

        .steps-content-form,
        .steps-action-form {
            padding: 0 15px;
        }
    }

    // Responsive para móviles
    @media (max-width: 480px) {

        .steps-content-form,
        .steps-action-form {
            padding: 0 10px;
        }
    }
}