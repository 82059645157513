.list-users {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &-switch {
      display: flex;
      align-items: center;

      > button {
        margin-right: 20px;
      }
    }
  }

  .users-active {
    background-color: #fff;
    padding: 10px 20px;

    .ant-list-item-meta {
      display: flex;
      align-items: center;
    }
  }
  .card-container {
    margin: 20px;
    padding: 15px;
    overflow: hidden;
    box-shadow: 0 2px 3px #f0f1f2;
    width: auto;
    min-width: 300px;
    border-radius: 5%;
    box-shadow:
    0 0 0 2px rgb(226, 226, 226),
    0.3em 0.3em 1em rgba(104, 104, 104, 0.3);
  }

  .card-container {
    display: inline-block;
    flex-wrap: wrap;
    justify-content: flex-start; /* Alinea las tarjetas al inicio del contenedor */
    gap: 10px; /* Espacio entre tarjetas */
    position: relative;
  }
  
 .avatar{
  margin-right: 10px;
  size: auto;
  width: auto;
 }
  
  .list-users .card-container {
    border: 1px solid #e8e8e8;
    margin: 20px;
    padding: 15px;
    overflow: hidden;
    box-shadow: 0 2px 3px #f0f1f2;
    width: auto;
}

  .message-button Button {
    margin: 10px;
  }

  .boton-flujo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  }
  
  // .accordion-content {
  //   position: absolute;
  //   overflow: hidden;
  //   width: auto; /* O el ancho que necesites */
  //   z-index: 10; /* Asegúrate de que sea mayor que el de los elementos subyacentes */
  //   background-color: white;
  // }
  .accordion-content {
    
    // width: 100%; /* o el ancho que desees */
    // z-index: 10; /* asegúrate de que se solape sobre otros elementos */
    // box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Opcional: para darle un poco de sombra y destacarlo */
    // background: #fff; /* o el color de fondo que prefieras */
    // left: 0;
    // /* Agrega un top que funcione para tu diseño, como la altura del botón del acordeón */
    // top: 100%;
  }
  

  .flex-row {
    display: block;
    flex-direction: row;
    align-items: center;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .vertical-divider {
    width: 1px;
    background-color: #ccc;
    margin-left: 10px;
    margin-right: 10px;
  }
  *{
    width: auto;
  }

  @media only screen and (max-width: 1118px), only screen and (max-height: 866px) {
    /* Tus estilos aquí */
    .card-container {
      display: inline-block;
      flex-wrap: wrap;
      justify-content: flex-start; /* Alinea las tarjetas al inicio del contenedor */
      gap: 10px; /* Espacio entre tarjetas */
    }
    .boton-flujo {
      display: inline;
      flex-direction: column;
      align-items: center;
      margin: 10px;
    }
  
    .fechas{
      margin: 5px;
    }
  
    .flex-row {
      display: block;
      flex-direction: row;
      align-items: center;
    }
    
    .flex-column {
      display: flex;
      flex-direction: column;
    }
    
    .vertical-divider {
      width: 1px;
      background-color: #ccc;
      margin-left: 10px;
      margin-right: 10px;
    }

    /* Puedes añadir más estilos específicos para otros elementos aquí */
}

  
}
