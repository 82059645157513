.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
  }
  
  .pagination li {
    margin: 5px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination li.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .pagination li:hover:not(.active) {
    background-color: #ddd;
  }
  
  /* Ocultar etiquetas de saltos ('...') en pantallas pequeñas */
  @media (max-width: 600px) {
    .pagination li.break {
      display: none;
    }
  }
  