// Dispositivos.scss

.map-container {
  border: 2px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 255, 0.1); /* Añadimos una sombra azulada */
  background-color: #fff; /* Fondo blanco */
  width:auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Distribución automática de columnas */
  gap: 20px;
}

.grid-item {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 255, 0.1); /* Añadimos una sombra azulada */
  background-color: #fff; /* Fondo blanco */
}

.switch {
  margin-bottom: 20px;
}

.button {
  border: none;
  border-radius: 50%; /* Hacemos el botón circular */
  padding: 10px 20px;
  background-color: #007bff; /* Color azul */
  color: #fff;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3; /* Color azul oscuro al pasar el mouse */
}
