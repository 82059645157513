.edit-user-form {
  .upload-avatar {
    display: table;
    margin: 0 auto;
    border: 2px solid #9a9a9a;
    border-style: dashed;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .form-edit {
    text-align: center;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    .btn-submit {
      width: 100%;
    }
  }
}

.ant-carousel {
  .slick-dots {
    //   position: static;
    //   margin-top: 20px;

    li button {
      width: 20px;
      height: 20px;
      background-color: grey;
    }
  }
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: rgb(59, 59, 59);
}
