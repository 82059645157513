// FormularioDNI.scss
.formulario-dni {
    margin: 0 auto;
    max-width: 500px; // Ancho máximo para dispositivos más grandes
    background-color: white;

    .login-form {
        // Estilos específicos para tu formulario
    }

    // Responsive para tablets
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 20px;
    }

    // Responsive para móviles
    @media (max-width: 480px) {
        .login-form__input, .login-form__button {
            width: 100%;
        }

        // Otros estilos específicos para móviles
    }
}