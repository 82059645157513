.Lavanderia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: "Arial", sans-serif;
}

// Button {
//   margin-bottom: 1rem;
// }

// Form {
//   width: 100%;
//   max-width: 300px;
// }
