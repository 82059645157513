@import "../../../scss/index.scss";
.center {
  position: absolute;
  top: 50%; /* posicionado en el medio del eje Y */
  left: 50%; /* posicionado en el medio del eje X */
  transform: translate(
    -50%,
    -50%
  ); /* mueve el elemento hacia arriba y hacia la izquierda en 50% de su tamaño */
}

.red-round-button {
  width: 35vw;
  height: 35vw; /* para mantener el tamaño proporcional */
  border-radius: 50%; /* para hacerlo redondo */
  font-size: 40px;
  font-weight: bold;
  color: white;
  border-color: black;
  background-size: cover; /* ajustar la imagen al tamaño del círculo */
  background-position: center; /* establecer la posición de la imagen de fondo en el centro del botón */

  /* Aplicar un degradado lineal desde arriba derecha a abajo izquierda como fondo */
  background: linear-gradient(to bottom left, rgba(2, 163, 243, 0.3), rgba(2, 163, 243, 1));
  filter: brightness(0.9); /* reducir el brillo a la mitad */

  /* Agregar sombreado con límites más definidos */
 /* Agregar sombra más ancha con límites definidos en el color #C2E6FC */
 box-shadow: 0 0 20px 10px rgba(194, 230, 252, 0.7), 0 0 10px 10px #DAF2FE; /* offsetX offsetY blurRadius spreadRadius color */
}


.red-round-button2 {
  background-image: url("../../../assets/img/jpg/PuertaCasa.jpg");
  width: 35vw;
  height: 35vw; /* para mantener el tamaño proporcional */
  border-radius: 50%; /* para hacerlo redondo */
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* establecer el color de fondo con una opacidad de 0.3 */
  font-size: 40px;
  filter: brightness(0.9); /* reducir el brillo a la mitad */
  font-weight: bold;
  color: white;
  border-color: black;
  background-size: cover; /* ajustar la imagen al tamaño del círculo */

  background-position: center; /* establecer la posición de la imagen de fondo en el centro del botón */
}

.welcome-section {
  //background-image: url("../../../assets/img/jpg/fondo-ciudad.jpg");

  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;


  
}

h1 {
  font-size: 100;
  color: white;
}
